const Arrow = ({ className = undefined }) => (
  <svg
    className={className}
    width="26"
    height="122"
    viewBox="0 0 26 122"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.1917 1.21984C23.7845 0.641209 24.7342 0.652736 25.3128 1.24559C25.8915 1.83845 25.8799 2.78812 25.2871 3.36675L23.1917 1.21984ZM24.2393 121.564L8.81465 113.685L23.3505 104.266L24.2393 121.564ZM25.2871 3.36675C1.48619 26.5967 -5.50478 68.4006 18.1962 109.482L15.5976 110.982C-8.67836 68.9031 -1.73968 25.5531 23.1917 1.21984L25.2871 3.36675Z"
      fill="url(#paint0_linear_2323_3304)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2323_3304"
        x1="24.2393"
        y1="121.564"
        x2="-25.7909"
        y2="103.463"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#E57054" />
        <stop offset="1" stopColor="#85254E" />
      </linearGradient>
    </defs>
  </svg>
)
export default Arrow
