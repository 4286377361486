const Arrow = ({ className = undefined }) => (
  <svg
    width="83"
    height="186"
    viewBox="0 0 83 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}>
    <path
      d="M54.2887 1.55156C53.7821 0.896106 52.84 0.775462 52.1846 1.28209C51.5291 1.78872 51.4085 2.73078 51.9151 3.38623L54.2887 1.55156ZM0.890526 183.213L18.0534 185.545L11.4909 169.515L0.890526 183.213ZM51.9151 3.38623C75.5278 33.9352 84.1263 68.802 77.6758 100.211C71.2302 131.596 49.7226 159.711 12.7502 176.738L14.0051 179.463C51.7632 162.074 73.9596 133.218 80.6145 100.814C87.2644 68.4343 78.3598 32.6935 54.2887 1.55156L51.9151 3.38623Z"
      fill="url(#paint0_linear_2323_4014)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2323_4014"
        x1="65.673"
        y1="-0.433364"
        x2="-63.0647"
        y2="77.3158"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#E57054" />
        <stop offset="1" stopColor="#85254E" />
      </linearGradient>
    </defs>
  </svg>
)

export default Arrow
