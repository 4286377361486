import cn from 'classnames'
import s from './styles.module.css'
import FsaBadge from 'components/payment/common/fsa-badge'
import BulletPoints from 'components/payment/common/bullet-points'
import { DESKTOP_WIDTH } from 'constants/breakpoints'
import { useWindowSizeCondition } from 'hooks/use-window-size-condition'
import { isServer } from 'utilities/environment'
import useIsFirstRender from 'hooks/use-is-first-render'
import DesktopArrow from './desktop-arrow'
import MobileArrow from './mobile-arrow'

const LandingPagePlanCard = ({
  products,
  options = { overrideDailyPrice: undefined },
  landingPageOptions = { overrideCTA: undefined },
  coupon,
  billingPeriodInMonths,
  dailyPrice,
  dailyPriceWithoutCoupon,
  pricingDescription,
  onSelection = () => {},
  isLandingPage = false,
  isCoachPage = false,
  loading = false,
  isFreeTrial = false,
  firstMonthPriceUnitAmount,
}) => {
  const isFirstRender = useIsFirstRender()
  const isDesktop = useWindowSizeCondition(({ width }) => width > DESKTOP_WIDTH)

  const dailyPriceWithoutCouponHasDecimals = dailyPriceWithoutCoupon % 1 !== 0
  const dailyPriceWithoutCouponDescription =
    '$' +
    (dailyPriceWithoutCouponHasDecimals
      ? dailyPriceWithoutCoupon.toFixed(2)
      : dailyPriceWithoutCoupon)

  if (isDesktop || isServer() || isFirstRender) {
    return (
      <div className={s.desktopContainer}>
        <div className={cn(s.landingPagePlanContainer, s.programCard)}>
          <div className={s.landingPageDesktopFsaBadgeContainer}>
            <span>FSA/HSA Eligible</span>
          </div>
          <div className={s.landingPagePlanHeaderContainer}>
            <div className={s.landingPageTitleHeader}>
              <span className={s.title}>Kickoff Program</span>
              <div className={s.fsaBadgeContainer}>
                <FsaBadge />
              </div>
            </div>
          </div>
          <div className={s.landingPagePlanBodyContainer}>
            <div className={s.landingPageBulletPointsSection1}>
              <BulletPoints
                productId={'LANDING_PAGE_SECTION_1'}
                className={s.ladingPageBulletPoints}
                checkStyle={s.ladingPageBulletPointsCheck}
                checkHeight={9}
                checkWidth={9}
                textStyle={s.landingPageBulletPointsSectionText}
              />
            </div>
            <div className={s.landingPageBulletPointsSection2}>
              <BulletPoints
                productId={'LANDING_PAGE_SECTION_2'}
                className={s.ladingPageBulletPoints}
                checkStyle={s.ladingPageBulletPointsCheck}
                checkHeight={9}
                checkWidth={9}
                textStyle={s.landingPageBulletPointsSectionText}
              />
            </div>
          </div>
        </div>
        <div className={s.landingPagePlanContainer}>
          <div className={s.fyiCallout}>
            FYI: Conventional in-person trainers and dietitians charge
            $100/Session!
            <DesktopArrow className={s.desktopArrow} />
          </div>
          <div className={s.fyiCallout}></div>
          <span className={s.title}>Pricing</span>
          <div className={s.landingPagePriceHeader}>
            <span className={s.landingPageDailyPrice}>
              <span className={s.price}>
                {dailyPriceWithoutCouponDescription}
              </span>
              <span>/day</span>
            </span>
          </div>
          <span className={s.landingPagePricingDescription}>
            {pricingDescription}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className={s.mobileContainer}>
      <div className={s.fyiCallout}>
        <MobileArrow className={s.arrow} />
        FYI: Conventional dietitians and in-person trainers charge
        $100+/Session!
      </div>
      <div className={s.landingPagePlanContainer}>
        <div className={s.landingPageDesktopFsaBadgeContainer}>
          <span>FSA/HSA Eligible</span>
        </div>
        <div className={s.landingPagePlanHeaderContainer}>
          <div className={s.landingPageTitleHeader}>
            <span className={s.title}>Kickoff Program</span>
            <div className={s.fsaBadgeContainer}>
              <FsaBadge />
            </div>
          </div>
          <div className={s.landingPagePriceHeader}>
            <span className={s.landingPageDailyPrice}>
              <span>{dailyPriceWithoutCouponDescription}</span>
              <span>/day</span>
            </span>
            <span className={s.landingPagePricingDescription}>
              {pricingDescription}
            </span>
          </div>
        </div>
        <div className={s.landingPagePlanBodyContainer}>
          <div className={s.landingPageBulletPointsSection1}>
            <BulletPoints
              productId={'LANDING_PAGE_SECTION_1'}
              className={s.ladingPageBulletPoints}
              checkStyle={s.ladingPageBulletPointsCheck}
              checkHeight={9}
              checkWidth={9}
              textStyle={s.landingPageBulletPointsSectionText}
            />
          </div>
          <div className={s.landingPageBulletPointsSection2}>
            <BulletPoints
              productId={'LANDING_PAGE_SECTION_2'}
              className={s.ladingPageBulletPoints}
              checkStyle={s.ladingPageBulletPointsCheck}
              checkHeight={9}
              checkWidth={9}
              textStyle={s.landingPageBulletPointsSectionText}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPagePlanCard
