import { billingPeriodInWords } from 'components/payment/helpers'
import {
  getDailyPrice,
  getMonthlyPrice,
  getPriceInDollars,
} from 'utilities/stripe'
// import { WORKOUT_AND_NUTRITION_PRODUCT_ID } from '../constants'

interface IGetPriceDetails {
  price: any
  product: any
  coupon: any
  isFreeTrial: boolean
}

export const getPriceDetails = ({
  price,
  product,
  coupon,
  isFreeTrial,
}: IGetPriceDetails) => {
  // const isWorkoutAndNutrition = product.id === WORKOUT_AND_NUTRITION_PRODUCT_ID
  const billingPeriodInMonths = price.billingPeriodInMonths
  const basePrice = product.prices.find(p => p.billingPeriodInMonths === 1)

  const [basePriceInDollars] = getPriceInDollars({
    price: basePrice,
  })

  const [baseDailyPriceInDollars, formattedBaseDailyPriceInDollars] =
    getDailyPrice({
      price: basePrice,
      roundTo: 10,
    })

  const priceWithoutMultiMonthDiscountInDollars =
    basePriceInDollars * billingPeriodInMonths

  const [dailyPriceWithoutMultiMonthDiscountInDollars] = getDailyPrice({
    price: basePrice,
    coupon,
  })

  const monthlyPriceWithoutMultiMonthDiscountInDollars = basePriceInDollars

  const priceWithoutMultiMonthDiscountInWords = `$${priceWithoutMultiMonthDiscountInDollars} billed ${
    billingPeriodInMonths === 6 ? 'every' : ''
  } ${billingPeriodInWords(billingPeriodInMonths)}`

  const [priceInDollars] = getPriceInDollars({
    price,
    coupon,
    isFree: isFreeTrial,
  })
  const [dailyPriceInDollars, formattedDailyPriceInDollars] = getDailyPrice({
    price,
    coupon,
    isFree: isFreeTrial,
    roundTo: 10,
  })
  const [monthlyPriceInDollars] = getMonthlyPrice({
    price,
    coupon,
    isFree: isFreeTrial,
  })
  const priceInWords = `$${priceInDollars} billed ${
    billingPeriodInMonths === 6 ? 'every' : ''
  } ${billingPeriodInWords(billingPeriodInMonths)}`

  return {
    priceInDollars,
    dailyPriceInDollars:
      billingPeriodInMonths > 1
        ? formattedDailyPriceInDollars
        : `$${dailyPriceInDollars}`,
    monthlyPriceInDollars,
    priceInWords,
    basePriceInDollars,
    baseDailyPriceInDollars: `$${baseDailyPriceInDollars}`,
    priceWithoutMultiMonthDiscountInDollars,
    dailyPriceWithoutMultiMonthDiscountInDollars,
    monthlyPriceWithoutMultiMonthDiscountInDollars,
    priceWithoutMultiMonthDiscountInWords,
  }
}
